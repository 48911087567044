const ActiveButtonDesign = {
  bgcolor: 'error.lighter',
  color: 'black',
  boxShadow: 'none',
  borderRadius: 12,
  '&.MuiButton-root:hover': {
    bgcolor: 'error.lighter'
  }
};
const InActiveButtonDesign = {
  bgcolor: 'background.paper',
  color: 'text.primary',
  boxShadow: 'none',
  borderRadius: 12,
  '&.MuiButton-root:hover': {
    bgcolor: 'background.paper'
  }
};
const FilterHolders: {} = {
  p: 1,
  bgcolor: 'background.paper',
  display: { xs: 'flex', sm: 'inline-flex' },
  gap: 1,
  flexWrap: 'wrap',
  borderRadius: { xs: 4, sm: 12 },
  flexDirection: { xs: 'column', sm: 'row' },
  justifyContent: 'center'
};

const statusDesignA = {
  color: 'black',
  bgcolor: 'error.lighter'
};

const TABLE_HEAD = [
  { id: 'number', label: 'number', alignRight: false },
  { id: 'customerName', label: 'customer name', alignRight: false },
  { id: 'customerNumber', label: 'customer number', alignRight: false },
  { id: 'customerGender', label: 'customer gender', alignRight: false },
  { id: 'masterCategoryName', label: 'category name', alignRight: false },
  { id: 'mobileRequestCategory', label: 'mobile category', alignRight: false },
  { id: 'referenceNumber', label: 'reference number', alignRight: false },
  { id: 'referencePackages', label: 'referencePackage', alignRight: false },
  { id: 'isPaid', label: 'Is Paid', alignRight: false },
  { id: 'bids', label: 'bids', alignRight: false },
  { id: 'status', label: 'status', alignRight: false },
  { id: 'createdDate', label: 'created date', alignRight: false },
  { id: '' }
];

const BID_HEADER = [
  { id: 'number', label: 'number', alignRight: false },
  { id: 'lawyerName', label: 'lawyer name', alignRight: false },
  { id: 'lawyerNumber', label: 'lawyer number', alignRight: false },
  { id: 'invoice', label: 'invoice', alignRight: false },
  { id: 'requestFulFillTimeInDays', label: 'request fullfil time in days', alignRight: false },
  { id: 'status', label: 'status', alignRight: false },
  { id: 'totalAmountWithVat', label: 'total amount', alignRight: false },
  { id: '', label: '' },
  { id: '', label: '' }
];

const TABLE_DOWNLOAD_HEAD = [
  { key: 'customer.firstName', label: 'اسم العميل' },
  { key: 'customer.lastName', label: 'لقب العميل' },
  { key: 'customer.phoneNumber', label: 'رقم العميل' },
  { key: 'referenceNumber', label: 'الرقم المرجعي' },
  { key: 'masterCategory.arabicName', label: 'اسم التصنيف' },
  { key: 'mobileRequestCategory.arabicName', label: 'فئة طلب الجوال' },
  { key: 'createdDate', label: 'تاريخ الإنشاء' }
];
const TABLE_DOWNLOAD_HEAD_lawyer = [
  { key: 'firstName', label: 'first name' },
  { key: 'number', label: 'number' },
  { key: 'lastName', label: 'last name' },
  { key: 'email', label: 'email' },
  { key: 'phone', label: 'phone number' },
  { key: 'status', label: 'status' },
  { key: 'isAvailable', label: 'isAvailable' },
  { key: 'isB2bLawyer', label: 'isB2bLawyer' },
  { key: 'isEmergencyLawyer', label: 'isEmergencyLawyer' }
];
const statusDesignB = {
  color: 'text.primary',
  bgcolor: 'divider'
};

export {
  ActiveButtonDesign,
  InActiveButtonDesign,
  FilterHolders,
  statusDesignA,
  TABLE_DOWNLOAD_HEAD,
  TABLE_HEAD,
  BID_HEADER,
  statusDesignB,
  TABLE_DOWNLOAD_HEAD_lawyer
};

export const CardsNames = [
  {
    id: 1,
    enName: 'New User Registered',
    arName: 'المستخدمين الجدد المسجلين'
  },
  {
    id: 2,

    enName: 'New Users Tried Booking Appointment',
    arName: 'عدد العملاء الجدد الذين حجزوا إستشارة'
  },
  {
    id: 3,

    enName: 'Successful Booked Appointment',
    arName: 'حجز موعد استشارة بنجاح'
  },
  {
    id: 4,

    enName: 'How Many Asked for Request',
    arName: 'عدد العملاء الجدد الذين قدموا طلبًا'
  }
];

// const getDate = (offsetMonth = 0) => {
//   // const date = new Date();
//   // date.setMonth(date.getMonth() - offsetMonth);
//   // return date.toISOString().split('T')[0];
// };
