import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import CheckMarkTrue from '@iconify/icons-eva/checkmark-circle-2-fill';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

type RequestMoreMenuProps = {
  onDelete: VoidFunction;
  requestId: string;
  statusList: string;
  canEdit?: Boolean;
  canDelete?: Boolean;
  onApprove?: VoidFunction;
  subscribedPackage?: any;
  onCreateMasterRequest?: VoidFunction;
  convertRequest?: VoidFunction;
  isChoosenByAdmin?: boolean;
};

export default function RequestMoreMenu({
  onDelete,
  requestId,
  statusList,
  canDelete = true,
  canEdit = true,
  onApprove,
  subscribedPackage,
  onCreateMasterRequest,
  convertRequest,
  isChoosenByAdmin
}: RequestMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { translate } = useLocales();

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {statusList === 'New' || (statusList === 'ConvertedToAppointment' && canDelete) ? (
          <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="إلغاء" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          ''
        )}
        {statusList === 'New' && subscribedPackage !== null && (
          <MenuItem onClick={onApprove} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon color="green" icon={CheckMarkTrue} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="موافقة" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {isChoosenByAdmin ? (
          <></>
        ) : (
          <>
            {subscribedPackage === null && statusList === 'New' && (
              <MenuItem onClick={convertRequest} sx={{ color: 'text.secondary' }}>
                <ListItemIcon>
                  <Icon icon={editFill} width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={translate('ConvertedToAppointment')}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </>
  );
}
