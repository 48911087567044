import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { paramCase } from 'change-case';
import { PATH_DASHBOARD } from 'routes/paths';

type PackageMoreMenuProps = {
  onDelete: VoidFunction;
  packageId: string | number;
  setEditPackageId: Function;
  setOpenPackageForm: Function;
  title: string;
  canDelete?: boolean;
  canEdit?: boolean;
  tag?: any;
  setOpenFuturePackageForm?: Function;
};

export default function PackageMoreMenu({
  onDelete,
  packageId,
  setEditPackageId,
  setOpenPackageForm,
  title,
  canDelete = true,
  canEdit = true,
  setOpenFuturePackageForm,
  tag
}: PackageMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const EditPackageHandler = () => {
    setEditPackageId(packageId);
    setOpenPackageForm(true);
    setIsOpen(false);
  };

  const EditFutureWorkPackageHandler = () => {
    setEditPackageId(packageId);
    if (setOpenFuturePackageForm) {
      setOpenFuturePackageForm(true);
    }
    setIsOpen(false);
  };
  const handleEditPackage = () => {
    if (tag?.includes('FutureWork')) {
      EditFutureWorkPackageHandler();
    } else {
      EditPackageHandler();
    }
  };
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {canDelete && (
          <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="حذف" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {canEdit && (
          <MenuItem onClick={handleEditPackage} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={title} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
