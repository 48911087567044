import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clockFill from '@iconify/icons-eva/clock-fill';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTheme } from '@mui/material/styles';
// material
import {
  Box,
  List,
  Badge,
  Avatar,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  CircularProgress
} from '@mui/material';
// utils
import { fToNow } from '../../utils/formatTime';
// components
import MenuPopover from '../../components/MenuPopover';
import { INotification } from '../../@types/notifications';
import { MIconButton } from '../../components/@material-extend';
import useAppointmentsNotification from '../../hooks/useAppointmentsNotification';
import {
  notificationsKey,
  useNotificationReadMutation,
  useNotificationsQuery
} from '../../queries/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { PATH_DASHBOARD } from 'routes/paths';
import { masterRequestStatusOptions } from 'utils/enums';
import InfiniteScroll from 'react-infinite-scroll-component';
import useLocales from 'hooks/useLocales';
import { dispatch } from 'redux/store';
import { setReduceCountNotification } from 'redux/slices/chat';

function NotificationItem({
  notification,
  onClick
}: {
  notification: INotification;
  onClick: () => void;
}) {
  const { translate } = useLocales();

  const linkGenerator = (messageType: any, status: any, referenceId: any) => {
    if (messageType === 'Appointment') {
      return `${PATH_DASHBOARD.appointments.admin}/${referenceId}/details/${0}`;
    } else if (messageType === 'MasterRequest') {
      return `${PATH_DASHBOARD.request.root}/masterRequest/${
        masterRequestStatusOptions[status]
      }/${referenceId}/detail/${0}`;
    } else if (messageType === 'DemoSchedule') {
      return `${PATH_DASHBOARD.scheduleDemo.details}?b2BDemoScheduleId=${encodeURIComponent(
        referenceId
      )}`;
    } else {
      return '#';
    }
  };

  return (
    <ListItemButton
      to={linkGenerator(
        notification?.messageType,
        notification?.status,
        notification?.referencceId
      )}
      component={RouterLink}
      onClick={onClick}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>
          <CalendarMonthIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant="subtitle2">{translate(notification?.messageType)}</Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{
                color: 'text.secondary'
              }}
            >
              {notification?.message}
            </Typography>
          </>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {notification?.date + ' ' + notification?.time}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function AppointmentsNotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const queryClient = useQueryClient();
  const { data, refetch } = useNotificationsQuery(page);
  const { mutate } = useNotificationReadMutation();

  const theme = useTheme();

  useEffect(() => {
    if (data?.data?.length) {
      setNotifications((prev) => [...prev, ...data?.data]);
    }
  }, [data]);

  const unReadNotifications = notifications.filter((item) => !item.isRead);
  const readNotifications = notifications.filter((item) => item.isRead);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    // handleMarkAllAsRead();
    // if (totalUnRead) mutate(0);
    // setNotifications((prevNotifications) =>
    //   prevNotifications.map((notification) => ({
    //     ...notification,
    //     isRead: true
    //   }))
    // );
    setOpen(false);
  };

  const handleNotificationClick = (id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, isRead: true } : notification
      )
    );
    setOpen(false);
    dispatch(setReduceCountNotification(Number(id)));
    refetch();
  };

  const handleMarkAllAsRead = () => {
    queryClient.setQueryData([notificationsKey], (prevData: INotification[] | undefined) => {
      return prevData?.map((notification) => ({
        ...notification,
        isRead: true
      }));
    });
  };

  const totallength = data && data.totalCount ? data?.totalCount : 0;

  const nextPage = () => {
    setPage((prev) => prev + 1);
  };

  useAppointmentsNotification();

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge
          badgeContent={data?.unReadCount}
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <NotificationsIcon />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 360,
          maxHeight: 500,
          border: '1px solid', // Border for all corners
          borderColor: theme.palette.divider, // Use theme's divider color
          borderRadius: 2 // Adjust for rounded corners
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">الإشعارات</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              لديك {data?.unReadCount} رسائل غير مقروءة
            </Typography>
          </Box>
        </Box>

        <Divider />

        {notifications.length ? (
          <InfiniteScroll
            dataLength={notifications.length}
            next={nextPage}
            hasMore={notifications.length < totallength}
            loader={
              <Box display="flex" justifyContent="center" sx={{ py: 2 }}>
                <CircularProgress />
              </Box>
            }
            height={415}
            scrollableTarget="scrollableDiv"
            className="scrollable-div"
          >
            {unReadNotifications.length ? (
              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    جديد
                  </ListSubheader>
                }
              >
                {unReadNotifications.map((notification, ind) => (
                  <NotificationItem
                    key={ind}
                    notification={notification}
                    onClick={() => handleNotificationClick(notification.id)}
                  />
                ))}
              </List>
            ) : null}

            {readNotifications.length ? (
              <List
                disablePadding
                subheader={
                  unReadNotifications.length ? (
                    <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                      قبل ذلك
                    </ListSubheader>
                  ) : undefined
                }
              >
                {readNotifications.map((notification, ind) => (
                  <NotificationItem
                    key={ind}
                    notification={notification}
                    onClick={() => handleNotificationClick(notification.id)}
                  />
                ))}
              </List>
            ) : null}
          </InfiniteScroll>
        ) : (
          <Typography align="center" sx={{ py: 2 }}>
            لا يوجد لديك أي إشعارات
          </Typography>
        )}
      </MenuPopover>

      {/* Add the CSS for scrollbar design */}
      <style>{`
        .scrollable-div::-webkit-scrollbar {
          width: 8px;
        }
        .scrollable-div::-webkit-scrollbar-thumb {
          background-color: ${theme.palette.primary.main};
          border-radius: 10px;
        }
        .scrollable-div::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 10px;
          height: 410px;
        }
      `}</style>
    </>
  );
}

export default AppointmentsNotificationsPopover;
