import Select from 'react-select';
import { isArray } from 'lodash';
import { CSSProperties, useState } from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme, useTheme } from '@mui/material/styles';
import useLocales from 'hooks/useLocales';

const customStyles = (
  menuStyles: CSSProperties | any,
  controleStyles: CSSProperties | any,
  singleValueStyles: CSSProperties | any,
  open: boolean,
  theme: Theme
) => {
  return {
    menu: (provided: any) => ({
      ...provided,
      ...menuStyles,
      backgroundColor: theme.palette.mode === 'dark' ? '#29343F' : 'white'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? theme.palette.mode === 'dark'
          ? '#415A70'
          : '#EAEAEA'
        : 'transparent',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#3A4A58' : '#F1F1F1'
      }
    }),

    control: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
      minHeight: '55px',
      borderRadius: 8,
      backgroundColor: theme.palette.background.paper,
      color: 'white !important',
      '&:focus': {
        boxShadow: 'none'
      },
      '&:hover': {
        boxShadow: 'none'
      },
      border: open ? '1px solid #d49e24 !important' : 'auto',
      ...controleStyles
    }),

    singleValue: (provided: any) => ({
      ...provided,
      ...singleValueStyles,
      color: theme.palette.mode === 'dark' ? 'white' : 'black'
    })
  };
};

type SelectProps = {
  onChange: any;
  className?: string;
  value: any;
  options?: any[];
  placeholder: string;
  isMulti?: boolean;
  menuStyles?: CSSProperties;
  controleStyles?: CSSProperties | any;
  singleValueStyles?: CSSProperties;
  containerStyles?: SxProps<Theme>;
  label?: string;
  valueName?: string;
  menuPlacement?: any;
  type?: string;
};

const defaultValue = (options: any, value: any, valueName: string, type?: string) => {
  return type === 'class'
    ? value
    : isArray(value)
    ? options?.filter(function (o1: any) {
        return value.some(function (o2: any) {
          return o1[`${valueName}`] === o2;
        });
      })
    : options?.find((option: any) => option.value === value) || null;
};

const ReactSelect = ({
  onChange,
  className,
  value,
  options,
  placeholder,
  isMulti,
  controleStyles,
  menuStyles,
  singleValueStyles,
  containerStyles,
  label,
  valueName,
  menuPlacement = 'bottom',
  type
}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Box
      className={className}
      sx={{
        ...containerStyles
      }}
    >
      <Select
        menuPlacement={menuPlacement}
        maxMenuHeight={160}
        onChange={(value) => onChange(value)}
        value={defaultValue(options, value, valueName ? valueName : 'value', type)}
        options={options}
        getOptionLabel={(option) => (label ? option[`${label}`] : option?.label)}
        getOptionValue={(val) => (valueName ? val[`${valueName}`] : val)}
        placeholder={placeholder}
        noOptionsMessage={() => translate('No Data Found')}
        onMenuOpen={() => {
          setOpen(true);
        }}
        onMenuClose={() => {
          setOpen(false);
        }}
        isMulti={isMulti}
        styles={customStyles(
          menuStyles || {},
          controleStyles || {},
          singleValueStyles || {},
          open,
          theme
        )}
      />
    </Box>
  );
};

export default ReactSelect;
