import * as Sentry from '@sentry/react';
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { BlogState } from '../../@types/blogs';
import axios from 'axios';
import { BLOGS_URL } from 'utils/axios';
// @types

// ----------------------------------------------------------------------

const initialState: BlogState = {
  isLoading: false,
  isAssigning: false,
  error: false,
  creatPostStatus: {},
  allPosts: {},
  postDetail: {},
  postDetailAdmin: {},
  deleteBlogStatus: {},
  updateBlogStatus: {}
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //Newly Added request for Monthly Reports

    createPostSuccess(state, action) {
      state.isLoading = false;
      state.creatPostStatus = action.payload;
    },

    getPostSuccess(state, action) {
      state.isLoading = false;
      state.allPosts = action.payload;
    },

    getSinglePostSuccess(state, action) {
      state.isLoading = false;
      state.postDetail = action.payload;
    },

    getSinglePostByAdminSuccess(state, action) {
      state.isLoading = false;
      state.postDetailAdmin = action.payload;
    },

    deleteBlogSuccess(state, action) {
      state.isLoading = false;
      state.deleteBlogStatus = action.payload;
    },
    updateBlogSuccess(state, action) {
      state.isLoading = false;
      state.updateBlogStatus = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------
//Newly Added request for Monthly Reports

// export function getEmployeeList( status:number,page:number, size:number) {
//     return async () => {
//       dispatch(slice.actions.startLoading());
//       try {

//           const response = await axios.get('/Employee/GetAllEmployees',{
//             params : {
//               status:status,
//               page : page,
//               size : size,
//             }
//           })

//           dispatch(slice.actions.getEmployeeListSuccess(response.data.result));

//       } catch (error) {
//         Sentry.captureException(error);
//         dispatch(slice.actions.hasError(true));
//       }
//     };
//   }

export function createPost(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let signIn = await axios.post(`${BLOGS_URL}/Account/SignIn`, {
        userName: 'ShwraAdmin',
        password: 'Admin!2345'
      });
      const response = await axios.post(`${BLOGS_URL}/Posts`, data, {
        headers: {
          Authorization: `Bearer ${signIn.data?.accessToken}`,
          'Content-Type': 'application/json',
          accept: 'text/plain'
        }
      });
      dispatch(slice.actions.createPostSuccess({ STATUS: true, ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createPostSuccess({ STATUS: false, error: error }));
    }
  };
}

export function getPosts(page: number, pageSize: number, searchName?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BLOGS_URL}/Posts`, {
        params: {
          page: page,
          pageSize: pageSize,
          status: 0,
          inName: searchName
        },
        headers: {
          'Accept-Language': localStorage.getItem('i18nextLng') === 'en' ? 'en-US' : 'ar-SA'
        }
      });
      dispatch(slice.actions.getPostSuccess(response?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getPostSuccess({ status: 'error', error: error }));
    }
  };
}

export function getSinglePost(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BLOGS_URL}/Posts/${id}`, {
        headers: {
          'Accept-Language': localStorage.getItem('i18nextLng') === 'en' ? 'en-US' : 'ar-SA'
        }
      });
      dispatch(slice.actions.getSinglePostSuccess(response?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getSinglePostSuccess({ status: 'error', error: error }));
    }
  };
}

export function getSinglePostByAdmin(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BLOGS_URL}/Posts/Admin/${id}`);
      dispatch(slice.actions.getSinglePostByAdminSuccess(response?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getSinglePostByAdminSuccess({ status: 'error', error: error }));
    }
  };
}

export function deleteBlog(id: String | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let signIn = await axios.post(`${BLOGS_URL}/Account/SignIn`, {
        userName: 'ShwraAdmin',
        password: 'Admin!2345'
      });
      const response = await axios.delete(`${BLOGS_URL}/Posts/${id}`, {
        headers: {
          Authorization: `Bearer ${signIn.data?.accessToken}`,
          'Content-Type': 'application/json',
          accept: 'text/plain'
        }
      });
      dispatch(slice.actions.deleteBlogSuccess({ STATUS: true, ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.deleteBlogSuccess({ STATUS: false, error: error }));
    }
  };
}

export function updatePost(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let signIn = await axios.post(`${BLOGS_URL}/Account/SignIn`, {
        userName: 'ShwraAdmin',
        password: 'Admin!2345'
      });
      const response = await axios.put(`${BLOGS_URL}/Posts`, data, {
        headers: {
          Authorization: `Bearer ${signIn.data?.accessToken}`,
          'Content-Type': 'application/json',
          accept: 'text/plain'
        }
      });
      dispatch(slice.actions.updateBlogSuccess({ STATUS: true, ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateBlogSuccess({ STATUS: false, error: error }));
    }
  };
}

export function setCreatePostStatus(data: any) {
  return dispatch(slice.actions.createPostSuccess(data));
}

export function setUpdateBlogStatus(data: any) {
  return dispatch(slice.actions.updateBlogSuccess(data));
}

export function setDeleteBlogStatus(data: any) {
  return dispatch(slice.actions.deleteBlogSuccess(data));
}

export function setGetPostStatus(data: any) {
  return dispatch(slice.actions.getSinglePostSuccess(data));
}

export function setGetPostByAdminStatus(data: any) {
  return dispatch(slice.actions.getSinglePostByAdminSuccess(data));
}

//--------------------------------------------------------------------------------------------
